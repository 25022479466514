import React, { useState } from "react";
import "./Fish.css";
import { images } from "../Helpers/FishData";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

function Fish() {
  const [currImg, setCurrImg] = useState(0);

  return (
    
    <div className="fish">
      <div width="400" height="500"
        className="fishInner"
        style={{ backgroundImage: `url(${images[currImg].img})` }}
      >
        <div
          className="left"
          onClick={() => {
            currImg > 0 && setCurrImg(currImg - 1);
          }}
        >
          <ArrowBackIosIcon style={{ fontSize: 30 }} />
        </div>
        <div className="center">
          <h1>{images[currImg].title}</h1>
          <p>{images[currImg].subtitle}</p>
          <h2>{images[currImg].text}</h2>
          <h3>{images[currImg].texty}</h3>
          <h6>{images[currImg].gertrude}</h6>
        </div>
        <div
          className="right"
          onClick={() => {
            currImg < images.length - 1 && setCurrImg(currImg + 1);
          }}
        >
          <ArrowForwardIosIcon style={{ fontSize: 30 }} />
        </div>
      </div>
    </div>
  );
}

export default Fish;